<script setup>
// import { fetchLcHasToken, fetchLcGetRefreshToken, fetchLcGetToken, fetchLcInvalidate } from '@/api/livechat'
import { LiveChatWidget } from '@livechat/widget-vue'
import { useStore } from 'vuex'
import { computed, ref } from 'vue'
import { fetchLcGetRefreshToken, fetchLcGetToken, fetchLcHasToken } from '@/api/livechat'

const store = useStore()

const userData = computed(() => store.state.userData)
const request = ref({
  redirect_url: '',
  client_id: '327ad5958f71023a0201635979a364c5',
  licenseId: '18627831',
  redirect_uri: 'https://mac988.com'
})
const lcCid = ref('')
const customIdentityProvider = () => {
  const getToken = async () => {
    try {
      const response = await fetchLcGetToken(request.value)
      const { resultCode, data } = response.data
      if (resultCode !== '200') {
        return null
      } else {
        data.token.entityId = data.token.__lc_cid
        lcCid.value = data.token.__lc_cid
        return data.token
      }
    } catch (error) {
      console.error('Error fetching token:', error)
    }
  }

  const getFreshToken = async () => {
    try {
      const response = await fetchLcGetRefreshToken(request.value)
      const { resultCode, resultMessage, data } = response.data
      if (resultCode !== '200') {
        console.error(resultMessage)
        return null
      } else {
        data.token.entityId = data.token.__lc_cid
        lcCid.value = data.token.__lc_cid
        return data.token
      }
    } catch (error) {
      console.error('Error fetching token:', error)
    }
  }

  const hasToken = async () => {
    try {
      const response = await fetchLcHasToken(request.value)
      const { resultCode } = response.data
      return resultCode === '200'
    } catch (error) {
      console.error('Error fetching token:', error)
    }
    return false
  }

  const invalidate = async () => {
    return true
    // try {
    //   const response = await fetchLcInvalidate(request.value)
    //   const { resultCode } = response.data
    //   return resultCode === 200
    //
    // } catch (error) {
    //   console.error('Error fetching token:', error)
    // }
  }

  return {
    getToken,
    getFreshToken,
    hasToken,
    invalidate: invalidate
  }
}
const customerInfo = computed(() => {
  return {
    customerName: userData.value.memName,
    customerEmail: `${userData.value.memId}@${userData.value.siteId}`
  }
})
// const sessionVariables = computed(() => {
//   return {
//     siteId: userData.value.siteId,
//     user_id: `${userData.value.memId}@${userData.value.siteId}`,
//     user_name: userData.value.memName
//   }
// })
// const eventHandler = (event) => {
//   console.log(event)
// }
const eventHandler = (event) => {
  // console.log(event)
}
</script>
<template>
  <LiveChatWidget @new-event="eventHandler" v-if="userData" license="18627831" :customIdentityProvider="customIdentityProvider" visibility="minimized" :customer-name="customerInfo.customerName" :customer-email="customerInfo.customerEmail" />
  <LiveChatWidget @new-event="eventHandler" v-else license="18627831"></LiveChatWidget>
</template>
