<template>
  <main-header :isLoggedIn="isLoggedIn" @leftSectionToggle="leftSectionToggle" @rightSectionToggle="rightSectionToggle"/>
  <transition>
    <main-nav-left v-if="isLoggedIn" :isActive="leftSectionActive"/>
  </transition>
  <transition>
    <main-nav-right :isActive="rightSectionActive" v-if="isLoggedIn" :userData="userData" @logout="logout"/>
  </transition>
  <router-view :key="$route.fullPath" :class="contents"></router-view>
  <main-footer></main-footer>
  <LiveChat></LiveChat>
  <loading></loading>
  <div id="layer-mask" @click="offLayerMask" :class="{isActive:layerMaskActive}"></div>
</template>

<script>

import MainHeader from './Header'
import MainNavLeft from './Navigation/Left'
import MainNavRight from './Navigation/Right'
import MainFooter from './Footer'
import Loading from '@/components/common/Loading.vue'
import { mapState } from 'vuex'
import LiveChat from '@/components/LiveChat.vue'
export default {
  name: 'MobileDefaultLayout',
  components: {
    MainNavLeft,
    MainNavRight,
    LiveChat,
    MainFooter,
    MainHeader,
    Loading
  },
  data() {
    return {
      isSlotOpen: false,
      groupCode: '',
      codeName: '',
      leftSectionActive: false,
      rightSectionActive: false,
      layerMaskActive: false,
      isLoggedIn: false
    }
  },
  computed: {
    ...mapState([
      'userData'
    ])
  },
  watch: {
    userData: {
      handler(value) {
        if (value) {
          this.isLoggedIn = true
        } else {
          this.isLoggedIn = false
        }
      },
      immediate: true
    },
    $route(to, from) {
      this.offLayerMask()
    }
  },
  mounted() {
    this.emitter.on('isSlot', (data) => {
      const groupCode = data.groupCode
      const codeName = data.codeName
      this.getSlotOpen(groupCode, codeName)
    })
  },
  methods: {
    setLoggedIn(value) {
      this.isLoggedIn = value
    },
    leftSectionToggle() {
      this.leftSectionActive = !this.leftSectionActive
      this.layerMaskActive = !this.layerMaskActive
    },
    rightSectionToggle() {
      this.rightSectionActive = !this.rightSectionActive
      this.layerMaskActive = !this.layerMaskActive
    },
    offLayerMask() {
      this.leftSectionActive = false
      this.rightSectionActive = false
      this.layerMaskActive = false
    },
    getSlotOpen(groupCode, codeName) {
      if (this.userData) {
        this.groupCode = groupCode
        this.codeName = codeName
        this.isSlotOpen = true
      } else {
        // this.onCheck('front.error.afterSignin')
        alert(this.$t('front.error.afterSignin'))
      }
    }
  }
}
</script>

<style>
.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  transition: opacity 0.2s ease-in;
}

.fade-leave-active {
  transition: opacity 0.1s ease-in;
}

.fade-leave-to {
  opacity: 0;
}

.slide-fade-enter {
  transform: translateX(10px);
  opacity: 0;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.2s ease;
}

.slide-fade-leave-to {
  transform: translateX(-10px);
  opacity: 0;
}

.slide-up-enter {
  transform: translateY(185px);
  opacity: 0;
}

.slide-up-enter-active,
.slide-up-leave-active {
  transition: all 0.3s ease;
}

.slide-up-leave-to {
  transform: translateY(0px);
  opacity: 0;
}

#layer-mask {
  position:fixed;
  display:none;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:50;
  background-color: #000000b3;
}
#layer-mask.isActive {
  display:block;
}
</style>
