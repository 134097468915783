<template>
  <footer>
    <!-- <img src="@/assets_mobile/img/winner_mobile/img_footer.png" alt="" width="100%" /> -->
    <div class="foot_menu">
      <ul>
        <li>
          <button @click="goPageByName('charge')">
            <img src="@/assets/img/core/deposit_active.png" alt="" />
            <br />
            <span>{{ $t('front.common.deposit') }}</span>
          </button>
        </li>
        <li>
          <button @click="goPageByName('exchange')">
            <img src="@/assets/img/core/withdraw_active.png" alt="" />
            <br />
            <span>{{ $t('front.common.withdrawal') }}</span>
          </button>
        </li>
        <li>
          <button href="#wrap" class="home" @click="goPageByName('mobileMain')">
            <img src="@/assets/img/core/goTop.png?v=1" alt="" />
          </button>
        </li>
        <li>
          <button @click="goPageByName('msg')">
            <img src="@/assets/img/core/message_active.png" alt="" />
            <br />
            <span>{{ $t('front.common.notes') }}</span>
          </button>
        </li>
        <li>
          <template v-if="siteOption">
            <button @click="goPageByName(siteOption.boardYn='N'?' noticeList':'boardList')">
              <img src="@/assets/img/core/notification_active.png" alt="" />
              <br />
              <span>{{ $t('front.common.notice') }}</span>
            </button>
          </template>
        </li>
      </ul>
    </div>
  </footer>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'Footer',
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>

<style scoped src="@/styles_mobile/header.css"></style>
<style scoped src="@/styles_mobile/swiper.min.css"></style>
<style scoped src="@/styles_mobile/subcommon.css"></style>
