import httpClient from '@/libs/http-client'
// 라이브챗 API
export function fetchLcGetToken(params) {
  const url = '/api/livechat/get-token'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export function fetchLcGetRefreshToken(params) {
  const url = '/api/livechat/get-refresh-token'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export function fetchLcHasToken(params) {
  const url = '/api/livechat/has-token'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
export function fetchLcInvalidate(params) {
  const url = '/api/livechat/invalidate-token'
  return httpClient.post(url, params).then(response => {
    return response
  })
}
