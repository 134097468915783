<template>
  <footer>
    <div class="foot_conts">
      <!-- <div class="foot_logo_copyright">
        <img src="@/assets/img/winner_pc/foot_logo_1.png" alt="" />
        <p>
          COPYRIGHT 2020, KCASINO. ALL RIGHTS RESERVED. GAMBLING CAN BE<br /> ADDICTIVE, PLEASE PLAY
          RESPONSIBLY.<br /> FOR MORE INFORMATION ON SUPPORT TOOLS,<br /> PLEASE VISIT OUR RESPONSIBLE
          GAMBLING PAGE
        </p>
      </div> -->
      <div class="foot_help">
        <div>
          <img src="@/assets/img/core/partners.png"  alt="">
        </div>
      </div>
      <div class="foot_menu">
        <dl>
          <dt>{{ $t('front.gnb.sportgame') }}</dt>
          <dd>
            <router-link :to="'/mypage'">{{ $t('front.common.mypage') }}</router-link>
          </dd>
        </dl>
        <dl>
          <dt>{{ $t('front.boardCategory.introduction') }}</dt>
          <dd>
            <router-link :to="'/casino'">{{ $t('front.gameCategory.casino') }}</router-link>
            <router-link :to="'/slot'">{{ $t('front.gameCategory.slot') }}</router-link>
            <router-link :to="'/mini'">{{ $t('front.gameCategory.minigame') }}</router-link>
            <router-link :to="'/sports'">{{ $t('front.gameCategory.sports') }}</router-link>
          </dd>
        </dl>
        <dl><a href="/"><img class="logo" src="@/assets/img/core/logo.png" /></a></dl>
        <dl>
          <dt>{{ $t('front.common.cscenter') }}</dt>
          <dd>
<!--            <router-link :to="'/qnaList'">{{ $t('front.common.qna') }}</router-link>-->
            <router-link :to="'/betlist'">{{ $t('front.common.betHistory') }}</router-link>
            <router-link :to="'/noticelist'">{{ $t('front.common.notice') }}</router-link>
            <router-link :to="'/messagelist'">{{ $t('front.common.notes') }}</router-link>
          </dd>
        </dl>
        <dl>
          <dt></dt>
          <dd>
            <!--<a href="/bbs/roulette.html">룰렛</a>-->
            <router-link :to="'/cash/deposit'">{{ $t('front.common.MakeAdeposit') }}</router-link>
            <router-link :to="'/cash/withdraw'">{{ $t('front.common.makeAwithdraw') }}</router-link>
            <router-link :to="'/point'">{{ $t('front.common.point') }}</router-link>
          </dd>
        </dl>
      </div>
    </div>
    <!-- <swiper :space-between="40" :grab-cursor="true" :a11y="false" :free-mode="true" :speed="11000" :loop="true"
      :slides-per-view="'auto'" :autoplay="{
          delay: 0.5, disableOnInteraction: false
        }" :breakpoints="{ /* when window >=0px - webflow mobile landscape/portriat */
      spaceBetween: 30,
      480: { /* when window >=0px - webflow mobile landscape/portriat */
        spaceBetween: 30,
      },
      767: { /* when window >= 767px - webflow tablet */
        spaceBetween: 40,
      },
      992: { /* when window >= 988px - webflow desktop */
        spaceBetween: 40,
      }
    }">
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_1.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_2.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_3.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_4.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_5.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_6.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_7.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_8.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_9.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_10.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_11.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_12.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_13.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_14.png" alt="" /></swiper-slide>
      <swiper-slide><img src="@/assets/img/winner_pc/foot_banner_15.png" alt="" /></swiper-slide>
    </swiper> -->
  </footer>
</template>

<script>
import { mapState } from 'vuex'
// import { Swiper, SwiperSlide } from 'swiper/vue'
// import 'swiper/swiper.min.css'git
export default {
  name: 'Footer',
  components: {
    // Swiper,
    // SwiperSlide
  },
  computed: {
    ...mapState([
      'siteOption'
    ])
  }
}
</script>
<style scoped>
.swiper-container {
  width:100%;
}
.swiper-wrapper {
  width:100%;
}
.swiper-slide {
  width:fit-content !important;
}
/*Add code for smooth scrolling*/
  .trusted-by-list {
   -webkit-transition-timing-function:linear!important;
   -o-transition-timing-function:linear!important;
   transition-timing-function:linear!important;
  }
.foot_help img{
  width:100%;
  height:36px;
}
</style>
